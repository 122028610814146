import Layout from 'components/layout/Layout';
import Hero from 'components/home/Hero';
import FeaturesHero from 'components/features/FeaturesHero';
import Explore from 'components/home/Explore';
import Meta from 'components/Meta';

export default function HomePage() {
  return (
    <Layout navigationDark>
      <Meta />
      <Hero />
      <FeaturesHero />
      <Explore />
    </Layout>
  );
}
