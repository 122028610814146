function Contact() {
  return (
    <div className="Contact">
      <div className="Container">
        <div className="Contact__content">
          <div className="Contact__column">
            <div className="Contact__item Contact__item--messenger">
              <span className="Contact__itemLabel">Chat with us</span>
              <a
                href="https://m.me/fingyapp"
                target="_blank"
                rel="noopener noreferrer"
                className="Contact__itemLink"
              >
                m.me/fingyapp
              </a>
            </div>
          </div>
          <div className="Contact__column">
            <div className="Contact__item Contact__item--email">
              <span className="Contact__itemLabel">Write us</span>
              <a
                href="mailto:hello@fingy.app"
                rel="noopener noreferrer"
                className="Contact__itemLink"
              >
                hello@fingy.app
              </a>
            </div>
          </div>
          <div className="Contact__column">
            <div className="Contact__item Contact__item--phone">
              <span className="Contact__itemLabel">Call us</span>
              <a
                href="tel:+420608307950"
                rel="noopener noreferrer"
                className="Contact__itemLink Contact__itemLink--slim"
              >
                +420 608 307 950
              </a>
            </div>
          </div>
          <div className="Contact__column Contact__column--grid">
            <div className="Contact__socialColumn">
              <a
                href="https://www.instagram.com/fingyapp"
                target="_blank"
                rel="noopener noreferrer"
                className="Contact__social Contact__social--instagram"
              >
                Instagram
              </a>
            </div>
            <div className="Contact__socialColumn">
              <a
                href="https://twitter.com/fingyapp"
                target="_blank"
                rel="noopener noreferrer"
                className="Contact__social Contact__social--twitter"
              >
                Twitter
              </a>
            </div>
            <div className="Contact__socialColumn">
              <a
                href="https://www.facebook.com/fingyapp"
                target="_blank"
                rel="noopener noreferrer"
                className="Contact__social Contact__social--facebook"
              >
                Facebook
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
