import Preview from 'components/home/Preview';
import HeroBoards from 'components/home/HeroBoards';

function Hero() {
  return (
    <div className="Hero NavigationTrack">
      <div className="Container">
        <h1>
          <span>Hangboard</span> trainings in your pocket
        </h1>
        <p>
          Practice for climbing with Fingy. Use vocal guidance, go through
          exercises made by well skilled climbers, follow your progress and
          strengthen your grip.
        </p>
        <a
          href={process.env.NEXT_PUBLIC_APPSTORE_URL}
          target="_blank"
          rel="noopener noreferrer"
          className="Button Button--hero"
        >
          <span className="Button__content">Get Fingy App</span>
        </a>
        <Preview />
        <HeroBoards />
      </div>
    </div>
  );
}

export default Hero;
