import Link from 'next/link';
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';

import { AppLinks } from 'utils/links';

interface NavigationProps {
  dark?: boolean;
}

function Navigation({ dark }: NavigationProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { asPath } = useRouter();

  const onScroll = useCallback(() => {
    if (!dark || !ref.current) return;

    const doc = document.documentElement;
    const offset = (window.scrollY || doc.scrollTop) - doc.clientTop;

    if (ref.current.classList.contains('Navigation--dark')) {
      ref.current.classList.remove('Navigation--dark');
    }

    if (ref.current.classList.contains('Navigation--light')) {
      ref.current.classList.remove('Navigation--light');
    }

    function isElementInViewPort(identifier: string) {
      const element = document.querySelector(identifier);
      const bounding = element?.getBoundingClientRect();
      if (!bounding) {
        return false;
      }

      return bounding.top + bounding.height > 0;
    }

    const isInViewPort = isElementInViewPort('.NavigationTrack');
    if (
      offset > 10 &&
      isInViewPort &&
      !ref.current.classList.contains('Navigation--dark')
    ) {
      ref.current.classList.add('Navigation--dark');
    } else if (
      !isInViewPort &&
      !ref.current.classList.contains('Navigation--light')
    ) {
      ref.current.classList.add('Navigation--light');
    }
  }, [dark]);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  return (
    <>
      <div className="Navigation__helper" />
      <div
        ref={ref}
        className={classNames('Navigation', {
          'Navigation--animated': dark,
          'Navigation--light': !dark,
        })}
      >
        <div className="Container">
          <div className="Navigation__wrapper">
            <Link href={AppLinks.homepage}>
              <a className="Navigation__brand">Fingy</a>
            </Link>
            <ul className="Navigation__menu">
              <li className="Navigation__menuItem">
                <Link href={AppLinks.features}>
                  <a
                    className={classNames('Navigation__menuItemLink', {
                      'Navigation__menuItemLink--active':
                        asPath === AppLinks.features,
                    })}
                  >
                    Features
                  </a>
                </Link>
              </li>
              <li className="Navigation__menuItem">
                <Link href={AppLinks.story}>
                  <a
                    className={classNames('Navigation__menuItemLink', {
                      'Navigation__menuItemLink--active':
                        asPath === AppLinks.story,
                    })}
                  >
                    Our Story
                  </a>
                </Link>
              </li>
              <li className="Navigation__menuItem">
                <a
                  className="Navigation__menuItemLink"
                  href="https://medium.com/fingy"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Blog
                </a>
              </li>
              <li className="Navigation__menuItem">
                <a
                  className="Navigation__menuItemLink Navigation__menuItemLink--promoted"
                  rel="noopener noreferrer"
                  href={process.env.NEXT_PUBLIC_APPSTORE_URL}
                  target="_blank"
                >
                  Download
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navigation;
