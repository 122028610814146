import { useEffect, useState } from 'react';

function Preview() {
  const [video, setVideo] = useState(false);
  useEffect(() => setVideo(!navigator.userAgent.match(/iPhone/i)), []);

  return (
    <div className="Preview">
      <div className="Preview__video">
        <video autoPlay loop muted poster={'/preview.jpg'}>
          {video && (
            <>
              <source src={'/preview.mp4'} type="video/mp4" />
              <source src={'/preview.webm'} type="video/webm" />
            </>
          )}
        </video>
      </div>
      <div className="Preview__overlay" />
    </div>
  );
}

export default Preview;
