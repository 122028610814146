import classNames from 'classnames';
import Marquee from 'react-fast-marquee';

function Instagram() {
  return (
    <div className="Instagram">
      <div
        className={classNames('Instagram__marquee', {
          'Instagram__marquee--loaded': true,
        })}
      >
        <Marquee gradient={false} style={{ position: 'relative', zIndex: 0 }}>
          <div className="Instagram__feed">
            <div />
          </div>
        </Marquee>
      </div>
      <div className="Instagram__content">
        <h3>#fingy community</h3>
        <p>
          Share your experience of improving your technique. Indoor, outdoor,
          anywhere in the world - becoming <strong>#fingy</strong> is our common
          goal.
        </p>
        <a
          href="https://www.instagram.com/fingyapp/"
          target="_blank"
          rel="noopener noreferrer"
          className="Button Button--outline Button--outlineDark"
        >
          Visit our Instagram
        </a>
      </div>
    </div>
  );
}

export default Instagram;
