import { useRouter } from 'next/router';
import Head from 'next/head';

interface MetaProps {
  title?: string;
  ogTitle?: string;
  description?: string;
  image?: string;
}

export default function Meta({
  title: pageTitle,
  ogTitle: pageOgTitle,
  description: pageDescription,
  image: pageImage,
}: MetaProps) {
  const { asPath } = useRouter();

  const title = pageTitle ?? 'Fingy ― Hangboard trainings in your pocket';
  const ogTitle =
    pageOgTitle ?? pageTitle ?? 'Fingy ― Hangboard trainings in your pocket';

  const image =
    pageImage ?? `${process.env.NEXT_PUBLIC_BASE_URL}social.jpg?v=002`;

  const description =
    pageDescription ??
    'Practice for climbing with Fingy. Use vocal guidance, go through exercises made by well skilled climbers, follow your progress and strengthen your grip.';

  return (
    <>
      <Head>
        <meta property="og:title" content={ogTitle} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={image} />
        <meta property="og:description" content={description} />

        <meta
          name="apple-itunes-app"
          content={`app-id=${process.env.NEXT_PUBLIC_APPSTORE_ID}`}
        />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@fingyapp" />
        <meta name="twitter:title" content={ogTitle} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />

        <meta
          property="fb:app_id"
          content={process.env.NEXT_PUBLIC_FACEBOOK_ID}
        />

        <title>{title}</title>

        <meta name="title" content={ogTitle} />
        <meta name="description" content={description} />
        <meta
          name="keywords"
          content="fingy, hangboard, beastmaker, beastmaker 1000, beastmaker 2000, megarail, climbing training, climbing training board, training board, training, app, ios, fitness, fingerboard"
        />
        <meta name="author" content="forCandies (https://forcandies.com)" />
        <meta name="copyright" content="(c) forCandies s.r.o." />

        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="theme-color" content="#ffffff" />

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no, user-scalable=0"
        />

        <link
          rel="canonical"
          href={`${process.env.NEXT_PUBLIC_BASE_URL}${asPath}`}
        />

        <link rel="stylesheet" type="text/css" href="/nprogress.css" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="msapplication-TileColor" content="#5e4037" />
        <meta name="theme-color" content="#6c4c4c" />

        <html lang="en" className="html--loaded" />
      </Head>
    </>
  );
}
