import { ReactNode } from 'react';

import Contact from 'components/layout/Contact';
import Footer from 'components/layout/Footer';

import Navigation from './Navigation';

interface LayoutProps {
  children: ReactNode;
  navigationDisabled?: boolean;
  navigationDark?: boolean;
}

function Layout({ children, navigationDisabled, navigationDark }: LayoutProps) {
  return (
    <div className="Layout">
      {!navigationDisabled && <Navigation dark={navigationDark} />}
      <div className="Layout__content">{children}</div>
      <Contact />
      <Footer />
    </div>
  );
}

export default Layout;
