import StoryHero from 'components/story/StoryHero';
import Blog from 'components/home/Blog';
import Instagram from 'components/home/Instagram';

function Explore() {
  return (
    <div className="Explore">
      <div className="Container">
        <h2>About Fingy</h2>
        <p className="Lead">
          Fingy originated in minds of two climbers and was created for all the
          fellow enthusiasts.
        </p>
        <StoryHero />
        <Blog />
        <Instagram />
      </div>
    </div>
  );
}

export default Explore;
