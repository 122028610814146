import { useRef } from 'react';
import Marquee from 'react-fast-marquee';

function HeroBoards() {
  const ref = useRef<HTMLDivElement>(null);
  return (
    <div className="Hero__boards">
      <h2 className="Hero__boardsTitle">
        compatible with many hangboards and we are willing to add more{' '}
        <a href="mailto:support@fingy.app">on your demand</a>
      </h2>
      <div className="Hero__boardsList" ref={ref}>
        <Marquee gradientColor={[45, 30, 27]}>
          <div className="Hero__boardsItem Hero__boardsItem--beastmaker1000">
            <span className="Hero__boardsItemTitle">Beastmaker 1000</span>
          </div>
          <div className="Hero__boardsItem Hero__boardsItem--beastmaker2000">
            <span className="Hero__boardsItemTitle">Beastmaker 2000</span>
          </div>
          <div className="Hero__boardsItem Hero__boardsItem--megarail">
            <span className="Hero__boardsItemTitle">Crusher Megarail</span>
          </div>
          <div className="Hero__boardsItem Hero__boardsItem--egurreHangboard">
            <span className="Hero__boardsItemTitle">eGUrre Hangboard</span>
          </div>
          <div className="Hero__boardsItem Hero__boardsItem--antMini">
            <span className="Hero__boardsItemTitle">Power Ant Mini</span>
          </div>
          <div className="Hero__boardsItem Hero__boardsItem--strongAnt3">
            <span className="Hero__boardsItemTitle">Strong Ant III</span>
          </div>
          <div className="Hero__boardsItem Hero__boardsItem--woodGripsCompact">
            <span className="Hero__boardsItemTitle">Wood Grips Compact II</span>
          </div>
          <div className="Hero__boardsItem Hero__boardsItem--woodgripsdeluxe2">
            <span className="Hero__boardsItemTitle">Wood Grips Deluxe II</span>
          </div>
          <div className="Hero__boardsItem Hero__boardsItem--unlimited">
            <span className="Hero__boardsItemTitle">Unlimited Board</span>
          </div>
          <div className="Hero__boardsItem Hero__boardsItem--whiteOakHangboard">
            <span className="Hero__boardsItemTitle">WhiteOak Hangboard</span>
          </div>
          <div className="Hero__boardsItem Hero__boardsItem--whiteOakPortable">
            <span className="Hero__boardsItemTitle">WhiteOak Portable</span>
          </div>
          <div className="Hero__boardsItem Hero__boardsItem--woodBoard">
            <span className="Hero__boardsItemTitle">WOOD BOARD</span>
          </div>
          <div className="Hero__boardsItem Hero__boardsItem--woodBoardHobby">
            <span className="Hero__boardsItemTitle">WOOD BOARD hobby</span>
          </div>
          <div className="Hero__boardsItem Hero__boardsItem--woodBoardProfi">
            <span className="Hero__boardsItemTitle">WOOD BOARD profi</span>
          </div>
          <div className="Hero__boardsItem Hero__boardsItem--workshopErgo">
            <span className="Hero__boardsItemTitle">Workshop 19/50 Ergo</span>
          </div>
          <div className="Hero__boardsItem Hero__boardsItem--simulator3d">
            <span className="Hero__boardsItemTitle">Metolius Simulator 3D</span>
          </div>
          <div className="Hero__boardsItem Hero__boardsItem--crux">
            <span className="Hero__boardsItemTitle">2Climb Crux</span>
          </div>
          <div className="Hero__boardsItem Hero__boardsItem--project">
            <span className="Hero__boardsItemTitle">Metolius Project</span>
          </div>
          <div className="Hero__boardsItem Hero__boardsItem--crimpTravel">
            <span className="Hero__boardsItemTitle">2Climb Crimp Travel</span>
          </div>
          <div className="Hero__boardsItem Hero__boardsItem--gamechanger">
            <span className="Hero__boardsItemTitle">2Climb Gamechanger</span>
          </div>
          <div className="Hero__boardsItem Hero__boardsItem--zlagboardpro2">
            <span className="Hero__boardsItemTitle">Zlagboard Pro 2.0</span>
          </div>
          <div className="Hero__boardsItem Hero__boardsItem--linebreakerbase">
            <span className="Hero__boardsItemTitle">Linebreaker BASE</span>
          </div>
          <div className="Hero__boardsItem Hero__boardsItem--kraxlboardrock">
            <span className="Hero__boardsItemTitle">Kraxlboard Rock</span>
          </div>
          <div className="Hero__boardsItem Hero__boardsItem--balknaSummit">
            <span className="Hero__boardsItemTitle">Balkna Summit</span>
          </div>
        </Marquee>
      </div>
    </div>
  );
}

export default HeroBoards;
