function Blog() {
  return (
    <div className="Blog">
      <h3>Blog</h3>
      <p>
        Once a month we publish an article about climbing, training methodology
        or news from Fingy team.
      </p>
      <a
        href="https://medium.com/fingy"
        rel="noopener noreferrer"
        target="_blank"
        className="Button Button--outline"
      >
        Read Our Blog
      </a>
    </div>
  );
}

export default Blog;
